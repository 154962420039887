<template>
  <div :class="!id ? 'content' : ''">
    <div class="row py-1">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="col d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button
                    type="cancel"
                    @click="$router.push({ name: 'customerList' })"
                >{{$t("goBack") }}</base-button>
              </div>
              <div v-if="isManager || isEmployee">
                <base-button
                    v-if="
                    id != null &&
                    customerType === 1
                  "
                    type="danger"
                    class="mr-2"
                    @click="isModalShow = true"
                >{{ $tc("delete") }}</base-button
                >
               <base-button
                    :disabled="isBtnDisabled"
                    type="success"
                    native-type="submit"
                    form="newCustomerForm"
                >{{ $t("save") }}</base-button
                >
              </div>
            </div>
            <div class="col d-flex">
              <div class="d-flex">
                <h1
                  v-if="id == null && (isManager || isEmployee)"
                  class="card-title mb-0"
                >
                  {{ $t(`routes.${this.$route.name}`) }}
                </h1>
                <h1 v-if="id != null && (isManager || isEmployee)" class="mb-0">
                  {{ customer.name }}
                </h1>
              </div>
            </div>
          </template>
          <form
            class="col py-2"
            @change="disabledBtn(false)"
            @submit.prevent="manageItem()"
            id="newCustomerForm"
          >
            <hr class="my-1" />
            <div class="py-4">
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    max="255"
                    v-model="customer.name"
                    :placeholder="$t('name')"
                    :label="`${$t('name')}*`"
                    required
                  ></base-input>
                </div>

              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    inputType="email"
                    v-model="customer.email"
                    :placeholder="$t('email')"
                    :label="`${$t('email')}`"
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    :disabled="customerType === 2"
                    inputType="number"
                    v-model="customer.phone"
                    :placeholder="$t('phone')"
                    :label="`${$t('phone')}*`"
                    required
                  ></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
                    $t("picture")
                  }}</label>
                  <div class="">
                    <image-upload
                      v-if="customerType === 1 || $route.name === 'newCustomer'"
                      @change="onImageChange"
                      :src="customer.picture"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="customer"
                    />
                    <avatar
                      v-else
                      :src="customerPicture"
                      :username="customer.name"
                      :rounded="false"
                      :size="250"
                    ></avatar>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <base-textarea
                      v-model.trim="customer.pivot.comment"
                      :placeholder="$t('notesAboutCustomer')"
                      :label="`${$t('notesAboutCustomer')}`"
                  ></base-textarea>
                </div>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  Card,
  ImageUpload,
  BaseTextarea,
} from "@/components/index";
import { mapState, mapActions } from 'vuex';

export default {
  name: "newCustomer",
  props: ["customerPicture", "customerType"],
  data: () => {
    return {
      id: null,
      roleId: 2,
      route: "/customers",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      picture: null,
      isModalShow: false,
      customer: {
        name: null,
        email: null,
        phone: null,
        pivot: {
          comment: null
        }
      },
    };
  },
  computed: {
    ...mapState(["isManager", "isEmployee"]),
  },
  methods: {
    createItem() {
      let customer = new FormData();
      if (this.customer.name) customer.append("name", this.customer.name);
      if(this.customer.email !== null && this.customer.email !== ''){
        customer.append("email", this.customer.email);
      }
      if (this.customer.phone) customer.append("phone", this.customer.phone);
      if(this.customer.pivot.comment !== null){
        customer.append("comment", this.customer.pivot.comment);
      }
      if (this.picture) customer.append("media", this.picture);
      return customer;
    },
    manageItem() {
      const customer = this.createItem();
      if (this.id != null && (this.isManager || this.isEmployee)) {
        customer.append("_method", "PUT");
        this.updateItem(customer);
        return;
      }
      this.storeItem(customer);
    },
    onImageChange(file) {
      this.picture = file;
    },
    async storeItem(customer) {
      this.disabledBtn(true);
      const response = await this.axios.post(this.route, customer);
      if (response && response.data.status === "success") {
        await this.$router.push({ name: "customerList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async updateItem(customer) {
      this.disabledBtn(true);
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.post(route, customer);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    ...mapActions(["setHasChangeForm"])
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    ImageUpload,
    BaseTextarea,
  },
};
</script>

<style scoped>
.buttonContainer {
  display: flex;
  justify-content: space-between;
}
</style>
